import type { ChangeEvent } from "react";
import { Inline, Pagination, TextInput } from "~/components/vendorUI";
import { Select } from "~/components/vendorUI/MultiSelect/Select";
import { useTranslation } from "~/utils";
import type { usePaginationFooter } from "./usePaginationFooter";
export { usePaginationFooter } from "./usePaginationFooter";

type PaginationFooterProps = ReturnType<
  typeof usePaginationFooter
>["compProps"] & {
  totalItems: number;
  onPageIndexChange?: (pageIndex: number) => void;
  onPageSizeChange?: (pageSize: number, pageNumber: number) => void;
  pageSizeArr?: readonly number[];
};

export const PaginationFooter = ({
  totalItems,
  onPageIndexChange = () => {},
  onPageSizeChange = () => {},
  pageSizeArr = [5, 10, 15, 20, 25],
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
  newPageNo,
  setNewPageNo,
  pageCount,
}: PaginationFooterProps) => {
  const { t } = useTranslation();

  return (
    <Inline align="center" distribute="end" gap="80px">
      <Pagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        setPageIndex={(pIdx) => {
          setPageIndex(pIdx);
          onPageIndexChange(pIdx);
        }}
      />
      <Inline align="center">
        <TextInput
          type="text"
          data-testid="gotopage-input"
          inputMode="numeric"
          name="goToPage"
          value={newPageNo}
          placeholder={t("REVIEWS_TABLE_PLACEHOLDER_GO-TO-PAGE")}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNewPageNo(e.target.value)
          }
        />
        <Select<number>
          value={pageSize}
          onChange={(size) => {
            setPageSize(size);
            setPageIndex(0);
            onPageSizeChange(size, 0);
          }}
          renderValue={(size) => t("REVIEWS_TABLE_SELECT_PAGE-SIZE", { size })}
        >
          {pageSizeArr
            .filter((size) => size <= totalItems)
            .map((size) => (
              <Select.Option
                key={size}
                value={size}
                renderLabel={(size) =>
                  t("REVIEWS_TABLE_SELECT_PAGE-SIZE", { size })
                }
              />
            ))}
        </Select>
      </Inline>
    </Inline>
  );
};
